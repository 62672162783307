import React from 'react';
import { useQuery } from '@tanstack/react-query';
import Alert from '../../../../../components/Alert/Alert';
import { globalInfosRef } from '../../../../../firebase/collection/collectionReferences';
import useGetDownloadUrl from '../../../../../ui/shared/global/useGetDownloadUrl/useGetDownloadUrl';
import AlertSeverity from '../../../../../ui/shared/severity/AlertSeverity';

const OutsideProvinceAdditionalSteps: React.FunctionComponent = () => {
  const { url } = useGetDownloadUrl('pdfTemplate/2688.pdf');
  const { data } = useQuery({
    queryKey: ['globalInfos'],
    queryFn: () => globalInfosRef().get()
  });
  const openPdf = () => {
    if (window.cordova) {
      // @ts-ignore
      // eslint-disable-next-line
      window.cordova.InAppBrowser.open(url, '_system');
    } else {
      window.open(url, '_blank');
    }
  };

  return (
    <Alert
      title="Étapes suivantes"
      severity={AlertSeverity.INFO}
      showIcon
      message={
        <ol>
          <li>
            SVP compléter la section A du formulaire{' '}
            <a style={{ textDecoration: 'underline', fontStyle: 'italic', cursor: 'pointer' }} onClick={openPdf}>
              Réclamation hors province
            </a>
          </li>

          <li>Faire signer le patient</li>

          <li>Signer et joindre le formulaire au carton</li>
        </ol>
      }
    />
  );
};

export default OutsideProvinceAdditionalSteps;
