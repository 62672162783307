/* eslint-disable no-param-reassign */
import { get } from 'lodash';
import moment from 'moment-timezone';
import {
  INTENSIVE_CARE_ON_GUARD_DAY,
  INTENSIVE_CARE_UNIT_SUPPORT_DAY
} from '../../../../shared/ramq/contextElements/globalContextElements';
import HOSPITAL_ACTIVITY_AREA_MAP from '../../../../shared/ramq/domainValues/activityAreas';
import {
  doctorIsOnIntensiveCareOnGuardOnThisDay,
  doctorIsOnIntensiveCareUnitSupportOnThisDay,
  getActsForSpecificDate,
  hasIntensiveCareOnGuardDayContextElement,
  shouldAddIntensiveCareUnitActivityArea,
  hasIntensiveCareUnitSupportDayContextElement
} from './intensiveCareOptimizations/utils';
import { isActBetween0And7 } from '../../../../shared/act/domain/utils';
import BillingType from '../../../../shared/domain/billing/model/BillingType';

const optimizeDocumentBeforeSave = async ({ document, userProfile }) => {
  await addIntensiveCarePatientSupportDayContextElement(document, userProfile);
  await addIntensiveCareOnGuardDayContextElement(document, userProfile);
  removePoolNumberIfBillingTypeIsPriv(document);
  return document;
};

const getActsToValidate = async (document, userProfile) => {
  const actsToValidate = [document];

  if (isActBetween0And7(document)) {
    const savedActsInDayBefore = await getActsForSpecificDate(
      moment(document.date).subtract(1, 'day').valueOf(),
      userProfile.id
    );

    actsToValidate.push(...savedActsInDayBefore);
  } else {
    const savedActsInCurrentDay = await getActsForSpecificDate(document.date, userProfile.id);
    const todayAt7 = moment(document.date).hours(7);

    return savedActsInCurrentDay.filter(({ start }) => moment(start).isSameOrAfter(todayAt7));
  }

  return actsToValidate;
};

const addIntensiveCarePatientSupportDayContextElement = async (document, userProfile) => {
  if (isActBetween0And7(document)) return;

  const actsToValidate = await getActsToValidate(document, userProfile);

  if (doctorIsOnIntensiveCareUnitSupportOnThisDay(actsToValidate)) {
    if (!hasIntensiveCareUnitSupportDayContextElement(document)) {
      document.contextElements = get(document, 'contextElements', []).concat(INTENSIVE_CARE_UNIT_SUPPORT_DAY);
    }

    if (shouldAddIntensiveCareUnitActivityArea(document)) {
      document.activityArea = HOSPITAL_ACTIVITY_AREA_MAP.intensiveCareUnit;
    }
  }
};

const addIntensiveCareOnGuardDayContextElement = async (document, userProfile) => {
  const actsToValidate = await getActsToValidate(document, userProfile);

  if (doctorIsOnIntensiveCareOnGuardOnThisDay(actsToValidate) && !hasIntensiveCareOnGuardDayContextElement(document)) {
    document.contextElements = get(document, 'contextElements', []).concat(INTENSIVE_CARE_ON_GUARD_DAY);
  }
};

const removePoolNumberIfBillingTypeIsPriv = (document) => {
  if (document.billingType === BillingType.PRIV) {
    delete document.poolNumber;
  }
};

export default optimizeDocumentBeforeSave;
