import Act from '../../../../shared/domain/activity/act/model/Act';
import { actFormFields } from '../../../containers/ActForm/constants';
import {
  getFirstDefaultActivityAreaFromCodes,
  listActivityAreasOfCodes
} from '../../../../shared/ramq/domainValues/codeActivityAreas';
import isDefined from '../../../../shared/utils/isDefined';
import {
  clinicalSupervisionForSpecialistInEmergencyClinicActivityArea,
  otherCodesValidAsEmergency
} from '../../../../shared/ramq/teachingCodes/constants';
import HOSPITAL_ACTIVITY_AREA_MAP from '../../../../shared/ramq/domainValues/activityAreas';
import {
  isUserSpecialtyGeneralSurgeon,
  isUserSpecialtyCardiologist
} from '../../../codeSpecificLogic/ui/rules/contextElements';

const hasCodesWithConflictingActivityAreas = (act: Act): boolean => {
  const activityAreas = listActivityAreasOfCodes(act).filter(isDefined);
  const hasMultipleActivityAreas = new Set(activityAreas).size > 1;

  if (isUserSpecialtyGeneralSurgeon()) {
    return hasMultipleActivityAreas && !allCodesAreValidForEmergencyActivityArea(act);
  }

  if (isUserSpecialtyCardiologist()) {
    return hasMultipleActivityAreas && !(act?.codes ?? []).every(({ code }) => ['900', '9160'].includes(code));
  }

  return hasMultipleActivityAreas;
};

const hasActivityAreaMismatch = (act: Act): boolean => {
  const codesActivityArea = getFirstDefaultActivityAreaFromCodes(act);
  const actOrActCodesHaveNoActivityArea = !act.activityArea || !codesActivityArea;
  const actHasEmergencyClinicActivityArea = act.activityArea === HOSPITAL_ACTIVITY_AREA_MAP.emergencyClinic;

  if (actHasEmergencyClinicActivityArea) {
    return false;
  }

  if (!isUserSpecialtyGeneralSurgeon()) {
    return false;
  }

  if (actOrActCodesHaveNoActivityArea) {
    return false;
  }

  return codesActivityArea !== act.activityArea;
};

const codeActivityAreaValidation = (act: Act) => {
  if (hasCodesWithConflictingActivityAreas(act)) {
    return {
      [actFormFields.codes]: {
        _error: "Les secteurs d'activités des codes choisis entrent en conflit"
      }
    };
  }

  if (hasActivityAreaMismatch(act)) {
    return {
      [actFormFields.activityArea]: 'Le secteur d’activité choisi ne correspond pas à celui du code entré'
    };
  }

  return {};
};

const allCodesAreValidForEmergencyActivityArea = (act: Act) =>
  act.codes.every(
    (actCode) =>
      clinicalSupervisionForSpecialistInEmergencyClinicActivityArea.includes(actCode.code) ||
      otherCodesValidAsEmergency.includes(actCode.code)
  );

export default codeActivityAreaValidation;
